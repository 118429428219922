import config from 'config'
export default function getFunctionScores () {
  if (!config.elasticsearch.hasOwnProperty('searchScoring')) {
    return false
  }

  const scoringConfig = config.elasticsearch.searchScoring;
  let filter = []
  let esScoringAttributes = scoringConfig.attributes

  if (!Object.keys(esScoringAttributes).length) {
    return false
  }
  for (const attribute of Object.keys(esScoringAttributes)) {
    for (const scoreValue of Object.keys(esScoringAttributes[attribute].scoreValues)) {
      let data = {
        'filter': {
          'match': {
            [attribute]: scoreValue
          }
        },
        'weight': esScoringAttributes[attribute].scoreValues[scoreValue].weight
      }
      filter.push(data)
    }
  }
  if (filter.length) {
    return {'functions': filter,
      'score_mode': scoringConfig.score_mode ? scoringConfig.score_mode : 'multiply',
      'boost_mode': scoringConfig.boost_mode ? scoringConfig.boost_mode : 'multiply',
      'max_boost': scoringConfig.max_boost ? scoringConfig.max_boost : 100,
      'min_score': scoringConfig.function_min_score ? scoringConfig.function_min_score : 1
    }
  }
  return false
}
